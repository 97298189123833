<template>
    <div>
      <Banner />
      <NavBar>
        <el-breadcrumb-item style="line-height: 41px;font-size: 12px;">
          <router-link to="/news">新闻中心</router-link>
        </el-breadcrumb-item>
      </NavBar>
      <div id="content">
        <ModuleTitle chinese="新闻中心" english="NEWS CENTER" style="margin-bottom:20px" />
        <div v-for="(item,index) in newsList" :key="index">
          <NewsCard 
            :news_id="item.id"
            :thumbnail_url="item.thumbnailUrl"
            :news_title="item.newsTitle"
            :news_content="item.newsContent"
            :news_releaseTime="item.releaseTime"
            :news_view="item.pageView"
          />
        </div>
        <WithoutContent v-if="newsList.length === 0" />
      </div>
    </div>
  </template>
  
  <script>
  import Banner from '../components/common/Banner'
  import NavBar from '../components/common/NavBar'
  import ModuleTitle from '../components/common/ModuleTitle'
  import NewsCard from '../components/news_components/NewsCard'
  import WithoutContent from '../components/common/WithoutContent'
  export default {
    name:"Humanresource",
    components:{
      Banner,
      NavBar,
      ModuleTitle,
      NewsCard,
      WithoutContent
    },
    data(){
      return{
        newsList:[]
      }
    },
    created(){
      this.$axios.get('/read/getNews').then(res => {
        this.newsList.push(...res)
      })
    }
  }
  </script>
  
  <style scoped>
    #content{
      max-width: 1200px;
      margin: auto;
      display: block;
    }
    #card{
      display: flex;
      box-sizing: border-box;
      padding: 14px 20px;
      border-bottom: 1px dashed #ccc;
      justify-content: space-between;
    }
  </style>