<template>
  <div id="card" @click="handleClick">
    <div class="thumbnail">
      <img :src="thumbnail_url" />
    </div>
    <div id="news_content">
      <span class="title">{{news_title}}</span>
      <span class="content">{{news_content.replaceAll('/n',' ').slice(0,125)}}......</span>
      <span class="showButton" @click="handleClick">
        <span class="buttonContent">查看更多</span>
        <span class="buttonBg"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name:"NewsCards",
  props:["news_id","thumbnail_url","news_title","news_content","news_releaseTime","news_view"],
  methods:{
    handleClick(){
      this.$router.push(`/news/detail/${this.$props.news_id}`)
    }
  }
}
</script>

<style scoped>
    #card{
      display: flex;
      box-sizing: border-box;
      padding: 14px 20px;
      border-bottom: 1px dashed #ccc;
      justify-content: space-between;
      cursor: pointer;
    }
    .thumbnail{
      width: 28%;
      height: 100%;
      max-width: 345px;
      max-height: 230px;
      overflow: hidden;
    }
    .thumbnail img{
      width: 100%;
      height: 230px;
      object-fit: cover;
    }
    #news_content{
      width: 68%;
    }
    .title{
      display: block;
      color: #333;
      font-weight:bold;
      font-family: "微软雅黑,Microsoft YaHei";
      font-size: 20px;
      line-height: 20px;
    }
    .content{
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      margin: 20px 0px;
      max-height: 152px;
      color: rgb(127, 127, 127);
      font-size: 16px;
      line-height: calc( 16px + 12px );
      font-family: "微软雅黑,Microsoft YaHei";
    }

    .showButton{
      width: 138px;
      display: flex;
      height: 30px;
      margin-top: 20px;
      background: #fff;
      transition: all .3s ease-out 0s;
      position: relative;
      box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
      justify-content: center;
      line-height: 30px;
    }
    @media (max-width:640px){
      .title{
        font-size: 14px;
      }
      .content{
        font-size: 12px;
        margin: 0px;
      }
      .showButton{
        margin: 5px;
      }
      #card{
        flex-direction: column;
      }
      .thumbnail{
        width: 100%;
        max-width: unset;
        max-height: 300px;
      }
      .thumbnail img{
        height: 300px;
      }
      #news_content{
        width: 100%;
        margin-top: 10px;
      }
      .showButton{
        display: none;
      }
    }
    @media (max-width:500px){
      .content{
        line-height: calc( 10px + 8px );
      }
    }
    .buttonBg{
      background-color: rgb(46, 84, 151);
      background-image: url("https://pic.imgdb.cn/item/63ae83e108b6830163123907.png");
      background-repeat: no-repeat;
      background-position:center;
      position: absolute;
      right: 0px;
      width: 30px;
      height: 30px;
      display: none;
    }

    #card:hover img{
      transform: scale(1.5,1.5);
      transition: 500ms;
    }

    #card:hover .title{
      color:#337ab7;
    }
    #card:hover .content{
      color:#999;
    }
    .showButton:hover .buttonBg{
      display: block;
    }
    .showButton:hover .buttonContent{
      transition: 500ms;
      margin-right: 30px;
    }
</style>