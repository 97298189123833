<template>
  <div id="banner" style="display:grid">
    <img style="width: 100%;" :src="bannerInfo.bannerImage[bannerInfo.currentIndex]" />
  </div>
</template>

<script>
export default {
  name:"Banner",
  data(){
    return{
      bannerInfo:{
        bannerImage:[
          'https://pic.imgdb.cn/item/63ae64b508b6830163e0339e.jpg',
          'https://pic.imgdb.cn/item/63ae64b508b6830163e033b4.jpg',
          'https://pic.imgdb.cn/item/63ae64b508b6830163e033c5.jpg',
          'https://pic.imgdb.cn/item/63ae64b508b6830163e033e3.jpg'
        ],
        currentIndex:0
      }
    }
  },
  created(){
    const path = this.$router.currentRoute.path
    switch (path){
      case '/news':
        this.$set(this.bannerInfo,'currentIndex',0)
        break;
      case '/human-resource':
      this.$set(this.bannerInfo,'currentIndex',1)
        break;
      case '/contact-us':
      this.$set(this.bannerInfo,'currentIndex',2)
        break;
      default:
        this.$set(this.bannerInfo,'currentIndex',3)
        break;
    }
  }
}
</script>

<style scoped>
  #banner{
      max-width: 1920px;
      margin: auto;
  }
</style>