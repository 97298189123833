<template>
  <div id="navBar">
    <div class="item">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><router-link to="/">首页</router-link></el-breadcrumb-item>
        <slot></slot>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name:"NavBar",
  data(){
    return{
      currentPage:{
        page:1
      }
    }
  },
}
</script>

<style scoped>
#navBar{
  background-color:rgba(242, 242, 242, 0.33);
  width: 100%;
  box-sizing: border-box;
}
span{
  font-size: 12px;
  line-height: 41px;
}
.item{
  max-width: 1200px;
  margin: auto;
  padding-left: 10px;
}
.el-breadcrumb__inner a{
  font-weight: normal;
  font-size: 12px;
  color: #333;
}
.el-breadcrumb__inner:hover a{
  color: #333;
}
</style>