<template>
  <div>
    <div id="NoContent">
      <img src="https://pic.imgdb.cn/item/63ae6c3108b6830163ec3df4.png" />
      <p>暂无资源</p>
    </div>
  </div>
</template>

<script>
export default {
  name:"WithoutContent"
}
</script>

<style scoped>
#NoContent{
  width: 100%;
  text-align: center;
  padding: 50px 20px 50px 0px;
}
#NoContent > p{
  margin-top: 20px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  line-height: 150%;
}
#NoContent > img{
  max-width: 148px;
  max-height: 148px;
}
</style>