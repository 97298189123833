<template>
    <div id="title">
        <div style="width:100%;position: absolute;">
            <span class="english" style="color:rgb(245, 245, 246)">
                {{english}}
            </span>
        </div>
        <div style="width:100%;position: absolute;margin-top: 15px;display: flex;justify-content: center">
            <div style="display: flex;justify-content: center;width: 90%;">
                <span class="chinese" style="color:rgb(46, 84, 151)">-</span>
                <span class="chinese" style="color:rgb(46, 84, 151)">
                    {{chinese}}
                </span>
                <span class="chinese" style="color:rgb(46, 84, 151)">-</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ModuleTitle",
    props:["chinese","english"]
}
</script>

<style scoped>
#title{
    width: 100%;
    margin: auto;
    min-height: 50px;
    text-align: center;
    position: relative;
    overflow: initial;
    margin-top: 60px;
    margin-bottom: 40px;
}
.chinese{
    font-size: 32px;
    line-height: 150%;
    opacity: 5;
    margin-top: 15px;
    font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
}
.english{
    font-size: 38px;
    font-weight: 700;
    opacity: 4;
    font-family: arial, helvetica, sans-serif;
}
@media (max-width:640px){
    .chinese{
        font-size: 28px;
    }
    .english{
        font-size: 32px;
    }
}
</style>